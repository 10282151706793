<template>
  <div class="flex flex-col min-h-screen">
    <SideBar :show-sidebar="showSidebar" @update:showSidebar="handleSidebarUpdate" />
    <div class="flex flex-col flex-1">
      <HeaderComponent class="sticky top-0 z-50" :show-sidebar="showSidebar" @toggle-sidebar="toggleSidebar" />
      <main class="flex-1 overflow-auto bg-gray-50 p-4">
        <router-view />
      </main>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import SideBar from "./components/SideBar.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    SideBar,
    FooterComponent,
  },
  setup() {
    const showSidebar = ref(false); // State to control sidebar visibility

    function toggleSidebar() {
      showSidebar.value = !showSidebar.value;
      console.log('Sidebar show status:', showSidebar.value);
    }

    // Handle the update:showSidebar event
    function handleSidebarUpdate(value) {
      showSidebar.value = value;
    }

    return { showSidebar, toggleSidebar, handleSidebarUpdate };
    // print the sidebar status
    
  },
};
</script>

<style> /* ... */ </style>

