<template>
  <div class="email-input-container flex items-center justify-center mb-8 pt-4">
    <div class="flex flex-col items-start">
      <div class="flex">
        <input
          type="email"
          placeholder="Enter your email"
          v-model="email"
          :class="{ 'border-red-500': !emailValid }"
          class="border-2 rounded py-2 px-4"
        />
        <button
          @click="emitEmail"
          class="bg-blue-500 text-white py-2 px-4 rounded ml-4"
        >
          Take a Wave
        </button>
      </div>
      <p v-if="!emailValid" class="text-red-500 text-xs italic mt-2">
        Please enter a valid email address.
      </p>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
      email: "",
      emailValid: true,
    };
  },
  methods: {
  emitEmail() {
    this.validateEmail();
    if (this.emailValid) {
      // Emit the valid email to the parent component
      this.$emit('submit-email', this.email);
    }
  },
    validateEmail() {
      // Simple email validation logic
      const re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.emailValid = re.test(this.email.toLowerCase());
    },
  },
};
</script>

<style scoped>
</style>


<style scoped>

</style>
