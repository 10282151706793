<template>
  <div class="flex flex-col items-center">
    <!-- Display the survey if it has not been submitted yet -->
    <div v-if="!submitted">
      <SurveyQuestion
        :data="currentQuestion"
        :key="currentQuestion.id"
        @answer="handleAnswer"
        @validation="handleValidation"
      />

      <div class="flex justify-center space-x-4 mt-4">
        <button
          v-if="!isFirstQuestion"
          @click="prevQuestion"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-24 h-10"
        >
          Previous
        </button>

        <button
          v-if="!isLastQuestion"
          @click="nextQuestion"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-24 h-10"
        >
          Next
        </button>

        <!-- Submit button only appears on the last question -->
        <button
          v-if="isLastQuestion"
          @click="submitSurvey"
          class="bg-blue-500 hover:bg-teal-500 text-white font-bold py-2 px-4 rounded w-24 h-10"
        >
          Submit
        </button>
      </div>
    </div>

    <!-- Display the success message if the survey has been submitted -->
    <div v-else class="flex flex-col justify-center items-center min-h-screen">
      <div class="text-center">
        <h2 class="text-3xl text-teal-500 font-bold mb-4">
          Thank you for participating!
        </h2>
        <p class="text-lg">Your survey has been submitted successfully.</p>
        <!-- You can add more content here, like a link back to the home page, or any other call to action -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SurveyQuestion from "./SurveyQuestion.vue";
//import EmailInput from "./EmailInput.vue";
import { questions } from "@/helpers/questionHelper"; // Ensure this path correctly points to your questionHelper.js

export default {
  components: {
    SurveyQuestion,
    //EmailInput,
  },
  props: {
    email: String, // Accepting email as a prop
  },
  data() {
    return {
      currentQuestionIndex: 0,
      questions, // Shorthand for questions: questions
      answers: {},
      isCurrentQuestionValid: false,
      submitted: false, // New property to track validation state of the current question
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    isFirstQuestion() {
      return this.currentQuestionIndex === 0;
    },
    isLastQuestion() {
      return this.currentQuestionIndex === this.questions.length - 1;
    },
  },
  methods: {
    nextQuestion() {
      if (this.isCurrentQuestionValid && !this.isLastQuestion) {
        this.currentQuestionIndex++;
      }
    },
    prevQuestion() {
      if (!this.isFirstQuestion) {
        this.currentQuestionIndex--;
      }
    },
    handleAnswer(answer) {
      // Vue.set(this.answers, this.currentQuestion.id, answer); // Uncomment for Vue 2
      this.answers[this.currentQuestion.id] = answer;
    },
    handleValidation(isValid) {
      this.isCurrentQuestionValid = isValid;
    },
    submitSurvey() {
      console.log("Email:", this.email);
      console.log("Answers:", this.answers);
      if (
        Object.keys(this.answers).length === this.questions.length &&
        this.email
      ) {
        const payload = {
          email: this.email,
          responses: this.answers,
        };

        axios
          .post("https://thebestsurf.com/api/survey/submit-survey", payload)
          .then(() => {
            // Handle success without using the response
            // For instance, you might want to clear the form, show a success message, or redirect
            this.submitted = true; // Update the submission state
          })
          .catch(() => {
            // Handle error without using the error object
            // Here you could alert the user to the failure
            alert(
              "There was an error submitting the survey. Please try again later."
            );
          });
      } else {
        alert("Please complete all questions and enter your email.");
      }
    },
  },
};
</script>