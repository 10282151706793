<template>
  <header class="bg-gray-100 text-blue-600 flex items-center p-4 justify-between">
    <!-- Flex container for toggle button and title -->
    <div class="flex items-center flex-grow md:flex-none">
      <!-- Toggle Button -->
<button @click="toggleSidebar" class="focus:outline-none">
        <!-- Using img tag for SVG icons -->
        <img
          v-if="showSidebar"
          src="@/assets/x.svg"
          alt="Close menu"
          class="w-6 h-6" 
        />
        <img
          v-else
          src="@/assets/menu.svg"
          alt="Open menu"
          class="w-6 h-6" 
        />
      </button>

      
      <!-- Logo/Title Centered on Mobile and next to Toggle on Desktop -->
      <h1 class="text-2xl mx-auto md:mx-0 my-0 md:ml-4 w-full md:w-auto text-center md:text-left">
        <a href="/" class="text-blue-600">thebestsurf.com</a>
      </h1>
    </div>

    <!-- Navigation Buttons Hidden on Mobile and Shown on Desktop -->
    <nav class="hidden md:flex items-center">
      <!-- Button without label -->
      <a href="#win" class="text-sm bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded mx-2">
        Win a surfboard
      </a>

      <!-- Button with label and indicator -->
      <div class="relative mx-4">
        <a href="#find" class="inline-block text-sm bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">
          Find a surfboard
        </a>
        <span class="absolute -top-2 -right-2 bg-teal-500 text-white text-xs px-2 rounded-full">
          Coming Soon
        </span>
      </div>
    </nav>
  </header>
</template>



<script>
export default {
  name: "HeaderComponent",
    props: {
    showSidebar: Boolean, // Define the prop to receive it from the parent component
  },
  methods: {
    toggleSidebar() { // Added method to emit the toggle-sidebar event
      this.$emit('toggle-sidebar');
    },
  },
};
</script>

