<template>
<div class="bg-gray-50">
  <div class=" max-w-4xl mx-auto px-8 md:px-24 py-8 ">
    <h1 class="text-4xl font-extrabold mb-6">Understanding Surfboard Types: <br>Characteristics and Surfing Styles</h1>
    
          <img 
    class="w-full object-cover rounded-xl mb-6" 
    :src="require('@/assets/Article1.jpg')" 
    alt="Surfing" 
    style="height: 40vh;"
  />
     
    <p class="mb-4 text-gray-600">
      Surfing is a diverse sport that accommodates various styles, skills, and wave conditions. The surfboard you choose plays a crucial role in how you interact with the waves, impacting everything from maneuverability to stability. In this article, we'll explore the different types of surfboards, their unique characteristics, and the surfing styles they best support, classified from shortest to longest.
    </p>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Shortboard</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Shortboards are known for their narrow, pointed nose and thinner profile. Typically ranging from 5 to 7 feet in length, they offer less buoyancy than other types, requiring more skill to paddle and catch waves.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Advanced surfers who prefer quick, sharp maneuvers and high-performance surfing. Ideal for fast, steep waves, shortboards are the go-to for those looking to execute tight turns, aerial tricks, and aggressive cutbacks.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Fish</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Fish surfboards are shorter, wider, and flatter than shortboards, with a distinctive swallow tail. They range from 5 to 6 feet in length and feature a wider nose, which helps increase planing surface and buoyancy.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Surfers looking for a board that performs well in small to medium-sized waves. The fish design is excellent for maintaining speed in weaker waves, offering a smooth, glide-like experience. It's a favorite among intermediate to advanced surfers seeking fun and playful sessions.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Mini Malibu (Mini-Mal)</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Mini-Mals are smaller versions of longboards, usually between 7 and 8.5 feet in length. They feature a wide, rounded nose, plenty of volume, and a gentle rocker, making them stable and easy to paddle.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Beginners and intermediate surfers. The mini-mal offers the stability and ease of a longboard with slightly more maneuverability, making it an excellent choice for those developing their skills and transitioning towards shorter boards.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Funboard</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Funboards, ranging from 7 to 8 feet in length, strike a balance between shortboards and longboards. They usually have a rounded nose and are thicker and wider than shortboards, making them more buoyant and stable.</p>
      <p class=" text-gray-600"><strong  class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Beginners and intermediate surfers. The funboard's design offers easier wave catching and stability, making it a great transition board for those moving from a longboard to a shortboard, or for anyone looking for a versatile board that performs in a variety of wave conditions.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Longboard</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Longboards are the traditional surfboard type, usually over 9 feet in length. They have a wide, rounded nose and a large surface area, providing excellent buoyancy and stability.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> All skill levels, particularly beginners and those who enjoy a laid-back surfing style. Longboards are perfect for small, slow waves, allowing for smooth glides, long rides, and nose riding. They're also great for crowded spots where catching waves early is an advantage.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Foamboard/Soft-top</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Foamboards, or soft-tops, are constructed with a foam core and a soft exterior. They come in various shapes and sizes, often mimicking other board types but with a focus on safety and durability.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Beginners and surf schools. The soft-top design reduces the risk of injuries, making them ideal for learning. They're also used by experienced surfers for fun, worry-free sessions in crowded or shallow spots.</p>
    </section>

    <section class="mb-8">
      <h2 class="text-2xl font-semibold mt-6 mb-2">Gun</h2>
      <p class="mb-2 text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Characteristics:</strong> Guns are long, narrow boards designed for big wave surfing. They can range from 7 to 12 feet, with a pointed nose and tail to help maintain control at high speeds and steep waves.</p>
      <p class=" text-gray-600"><strong class = "underline decoration-2 decoration-sky-500">Suitable for:</strong> Experienced surfers tackling large, powerful waves. Guns are specifically designed for big wave spots, offering the speed, stability, and control needed to navigate the challenges of large swell conditions.</p>
    </section>

    <p class=" text-gray-600">Each surfboard type caters to different wave conditions, skill levels, and surfing styles. Whether you're just starting out or are an experienced surfer, there's a board that fits your needs. Understanding the characteristics and suitable conditions for each type can help you make the best choice for your next session on the waves.</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'ArticlePage',
};
</script>

<style scoped>
/* Tailwind CSS is utility-first, so most styling can be done directly within the template.
However, if you need custom styles, you can add them here. */
</style>