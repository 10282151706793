<template>
  <div class="my-8">
    <!-- Question Text -->
    <h3 class="text-lg font-semibold mb-4">{{ data.text }}</h3>

<!-- Multiple Choice Questions -->
  <div v-if="data.type === 'multiple-choice' || data.type === 'multiple-choice-with-other'" class="space-y-4">
    <div v-for="option in data.options" :key="option.value" class="my-2">
      <!-- Option Button -->
      <button
        :class="{
          'bg-blue-500 text-white': response === option.value,
          'bg-white text-gray-700': response !== option.value,
          'hover:bg-blue-500 hover:text-white': true
        }"
        class="border border-gray-300 rounded-full py-2 px-6 w-full text-center transition-colors duration-200"
        :value="option.value"
        @click="response = option.value"
      >
        {{ option.label }}
      </button>
      <!-- Additional Text Input for 'Other' option -->
      <input
        v-if="option.value === 'other' && response === 'other'"
        type="text"
        v-model="otherResponse"
        class="mt-2 p-2 border rounded border-gray-300 w-full"
      />
    </div>
  </div>

    <!-- Number Input Type -->
    <div v-else-if="data.type === 'number'" class="mt-4">
      <input type="number" v-model.number="response" :min="data.validation.min" :max="data.validation.max" :placeholder="data.placeholder" class="p-2 border rounded w-full border-gray-300" />
    </div>

  <div v-else-if="data.type === 'range-slider'" class="mt-4">
    <div v-for="subQuestion in data.subQuestions" :key="subQuestion.id" class="my-4">
      <label class="block mb-2 text-lg text-gray-700">{{ subQuestion.label }}</label>
      <input
        type="range"
        :min="subQuestion.min"
        :max="subQuestion.max"
        :step="subQuestion.step"
        v-model="responses[subQuestion.id]"
        class="w-full"
      />
      <!-- Value Display -->
      <span class="block text-right text-gray-700">{{ displayFraction(responses[subQuestion.id]) }}</span>
    </div>
  </div>


    <!-- Scrolling List Input Type -->
    <div v-else-if="data.type === 'scrolling-list'" class="mt-4">
      <!-- Dropdown select for brand choices -->
      <select v-model="response" class="p-2 border rounded w-full border-gray-300">
        <option disabled value="">Please select a brand</option>
        <option v-for="brand in data.options" :key="brand" :value="brand">{{ brand }}</option>
      </select>
    </div>
  </div>
</template>





<script>
export default {
  props: {
    data: Object, // The question data passed from the parent component
  },
  data() {
    return {
      response: null, // Stores the user's response
      otherResponse: "", // Stores the user's input for 'Other' option in multiple-choice questions
      responses: {}, // Stores responses for range slider sub-questions
      isValid: false, // Tracks if the current question's response is valid
    };
  },
  methods: {
    validateResponse() {
      // Validates the response based on the question type
      if (this.data.type === 'multiple-choice' || this.data.type === 'multiple-choice-with-other') {
        this.isValid = !!this.response; // Checks if a response is selected
      } else if (this.data.type === 'number') {
        // Checks if the number response is within the specified range
        this.isValid = this.response !== null && this.response >= this.data.validation.min && this.response <= this.data.validation.max;
      } else if (this.data.type === 'range-slider' || this.data.type === 'scrolling-list') {
        // Assumes range sliders and scrolling lists are always valid
        this.isValid = true;
      }
      // Emits the validation state to the parent component
      this.$emit("validation", this.isValid);
    },
  displayFraction(value) {
    // Separate the whole number and the decimal part
    let [whole, fraction] = value.toString().split('.');

    // If there is no fraction or it's zero, just return the whole number
    if (!fraction || fraction === '0') {
      return whole;
    }

    // Convert the decimal part to a fraction
    fraction = this.decimalToFraction(`0.${fraction}`);
    
    // Combine whole number and fraction for display, omit fraction if it's 0
    return `${whole}${fraction !== '0' ? ` ${fraction}` : ''}`;
  },
  decimalToFraction(decimal) {
    const gcd = (a, b) => (b ? gcd(b, a % b) : a);
    let [top, bottom] = decimal.toString().includes('.') ? decimal.toString().split('.') : [decimal, 0];
    top = Number(top) * (bottom ? Math.pow(10, bottom.length) : 1) + Number(bottom);
    bottom = bottom ? Math.pow(10, bottom.length) : 1;
    const divisor = gcd(top, bottom);
    top /= divisor;
    bottom /= divisor;
    return bottom === 1 ? String(top) : `${top}/${bottom}`;
  },
  },
  watch: {
    data(newVal, oldVal) {
      // Resets responses when a new question is loaded
      if (newVal.id !== oldVal.id) {
        this.response = newVal.type === 'number' ? null : this.response;
        this.otherResponse = "";
        this.isValid = false;
        this.validateResponse();
      }
    },
    response(newVal) {
      // Validates and emits the response when it changes
      this.validateResponse();
      if (this.isValid) {
        this.$emit("answer", newVal);
      }
    },
    otherResponse(newVal) {
      // Handles validation for 'Other' response in multiple-choice questions
      this.validateResponse();
      if (this.response === "other" && this.isValid) {
        this.$emit("answer", newVal);
      }
    },
  responses: {
    handler(newVal) {
      // Validate the response before emitting
      this.validateResponse();
      if (this.isValid) {
        // Emit an 'answer' event with an object containing all responses
        // The parent component will receive this event and can handle it just like other question types
        this.$emit("answer", { questionId: this.data.id, answer: newVal });
      }
      },
      deep: true,
    },
  },
  created() {
  if (this.data.type === "range-slider") {
    this.data.subQuestions.forEach((sq) => {
      this.responses[sq.id] = sq.min; // Or any other default value within the valid range
    });
    this.validateResponse(); // Validate on creation
  } else {
    this.validateResponse();
  }
  },
};
</script>
