<template>
 <footer class="bg-gray-800 text-white text-center pt-4 pb-2 w-full ">
    <div class="container mx-auto">
      <nav class="flex justify-evenly">
        <a href="/contact" class="hover:underline">Contact</a>
        <a href="/partnership" class="hover:underline">Partnership</a>
        <a href="/shop" class="hover:underline">I'm a shop</a>
        <a href="/faq" class="hover:underline">FAQ</a>
      </nav>
      <p class="text-sm mt-3 ml-4">Made by surfers for surfers</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  // Include any data, computed properties, methods, or lifecycle hooks here
}
</script>

