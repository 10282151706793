// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import ContactPage from '@/views/ContactPage.vue';
import ArticleSurfboardsTypes from '@/views/ArticleSurfboardsTypes.vue';
import Marketplace from '@/views/MarketPlace.vue';
import ProductDetails from '@/views/ProductDetails.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage // Define the route for the contact page
  },
  {
    path: '/article1', // Define the path for the article page
    name: 'ArticleSurfboardsTypes',
    component: ArticleSurfboardsTypes, // Assign the ArticlePage component to this route
  },
  {
    path: '/marketplace/:productType', // Define the path for the marketplace page
    name: 'Marketplace',
    component: Marketplace, // Assign the Marketplace component to this route
  },
  {
    path: '/products/:productId',
    name: 'ProductDetails',
    component: ProductDetails
  },
  // ...other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
