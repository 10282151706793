<template>
  <aside :class="sidebarClasses" ref="sidebar" @click="handleNavClick">
    <div class="flex items-center justify-between mx-2 pt-0">
      <button @click.stop="closeSidebar" class="text-2xl text-blue-600 focus:outline-none">✕</button>
      <h1 class="text-2xl">
        <router-link to="/" class="text-blue-600">thebestsurf.com</router-link>
      </h1>
      <div class="text-2xl opacity-0">✕</div>
    </div>
    <nav class="space-y-4 text-gray-600">
      <div class="space-y-1">
        <router-link to="/login" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
          <span class="ml-3 font-medium">Login</span>
        </router-link>
        <!-- Placeholder for other items... -->
      </div>
      <div class="mt-4 space-y-1">
        <router-link to="/article1" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
          <span class="ml-3 font-medium">The Surf Guide</span>
        </router-link>
        <router-link to="/browse-board" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
          <span class="ml-3 font-medium">Surf</span>
        </router-link>
        <div @click="toggleKitesurfMenu" class="flex items-center p-2 hover:bg-gray-200 rounded-lg cursor-pointer">
          <span class="ml-3 font-medium">Kitesurf</span>
        </div>
        <div v-if="showKitesurfMenu" class="ml-6 space-y-1">
          <router-link to="/Marketplace/Kite" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
            <span class="ml-3 font-medium">Kite</span>
          </router-link>
          <router-link to="/Marketplace/Twintip" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
            <span class="ml-3 font-medium">Board</span>
          </router-link>
          <router-link to="/marketplacebar" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
            <span class="ml-3 font-medium">Bar</span>
          </router-link>
        </div>
        <router-link to="/find-surfcamp" class="flex items-center p-2 hover:bg-gray-200 rounded-lg">
          <span class="ml-3 font-medium">Windsurf</span>
        </router-link>
        <!-- Placeholder for other items... -->
      </div>
    </nav>
  </aside>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  showSidebar: Boolean,
});

const emit = defineEmits(['update:showSidebar']);

const sidebarClasses = computed(() => ({
  'bg-white shadow space-y-6 py-7 px-2': true,
  'fixed inset-y-0 left-0 z-10 transform transition duration-200 ease-in-out': true,
  'translate-x-0': props.showSidebar,
  '-translate-x-full': !props.showSidebar,
  'w-full lg:w-1/4': true,
}));

const showKitesurfMenu = ref(false);

const toggleKitesurfMenu = () => {
  showKitesurfMenu.value = !showKitesurfMenu.value;
};

const closeSidebar = () => {
  emit('update:showSidebar', false);
};

const handleNavClick = (event) => {
  if (event.target.closest('a')) {
    closeSidebar();
  }
};
</script>