<template>
  <div class="product-details container mx-auto p-4 bg-white shadow-lg rounded-lg">
    <!-- Check if product data is available -->
    <div v-if="Object.keys(product).length" class="flex flex-col items-center">
      <!-- Product data is available -->
      <img :src="product.image_url" alt="Product image" class="object-contain h-48 w-full md:h-96" />
      <div class="text-center mt-6">
        <h1 class="text-2xl font-semibold">{{ product.brand }} {{ product.model_name }}</h1>
        <p class="text-md text-gray-600">{{ product.product_type }}</p>
      </div>
    </div>
    <div v-else class="flex justify-center items-center h-48 md:h-96">
      <!-- Placeholder or loading indicator when product data is not available -->
      <span>Loading...</span>
    </div>
    <div class="mt-8 text-center" v-if="characteristics.length > 0 && characteristicColumns.length > 0">
      <table class="table-auto w-full">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th class="px-4 py-2" v-for="column in characteristicColumns" :key="column">{{ column }}</th>
          </tr>
        </thead>
        <tbody class="text-sm divide-y divide-gray-200">
          <tr v-for="characteristic in characteristics" :key="characteristic.product_id" class="hover:bg-gray-100">
            <td class="px-4 py-2" v-for="column in characteristicColumns" :key="column">{{ characteristic[column] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axiosConfig.js";

export default {
  data() {
    return {
      product: {},
      characteristics: [],
      characteristicColumns: [],
      columnOrderMapping: null // Store the mapping here 
    };
  },
  methods: {
    fetchColumnOrderMapping() {
      // Fetch the column order mapping from the backend
      axios.get(`/api/product-characteristics-mapping`).then(response => {
        this.columnOrderMapping = response.data;
        // After getting the mapping, fetch the product details
        this.fetchProductDetails();
      }).catch(error => {
        console.error("Error fetching the column order mapping:", error);
        // Handle the error as you see fit
      });
    },
    fetchProductDetails() {
      const productId = this.$route.params.productId;
      axios.get(`/api/products/${productId}`).then(response => {
        this.product = response.data;
        // Now that you have the mapping and product type, fetch the characteristics
        this.fetchCharacteristics(this.product.product_type, productId);
      });
    },
    fetchCharacteristics(productType, productId) {
      axios.get(`/api/product-characteristics/${productType}/${productId}`).then(response => {
        this.characteristics = response.data;
        if (this.columnOrderMapping && this.columnOrderMapping[productType]) {
          // Set the columns based on the order provided by the backend
          this.characteristicColumns = this.columnOrderMapping[productType];
        } else {
          // If the mapping isn't available, use the order from the response (less ideal)
          this.characteristicColumns = this.characteristics.length > 0 ? Object.keys(this.characteristics[0]) : [];
        }
      });
    }
  },
  mounted() {
    // When the component is mounted, first fetch the column order mapping
    this.fetchColumnOrderMapping();
  }
};
</script>