import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import './assets/global.css';
import http from './services/axiosConfig'; // Import the Axios instance

const app = createApp(App);

app.config.globalProperties.$http = http;

app.use(router); // Use the router

app.mount('#app');