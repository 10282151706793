<template>
  <div class="bg-gray100 text-blue-600 flex flex-col ">
    <!-- Title Section -->
    <div class=" text-wrap text-center pt-4">
      <section class=" text-center py-6 w-full">
        <h2 id ="title" class="text-4xl font-bold">

        </h2>
      </section>
    </div>

<div class=" mx-8 mb-8">
      <!-- Text Description Sections with conditional layout -->
      <section class="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 py-6 px-8">
        <!-- First Text Description Section -->
        <div class="flex-1">
          <p class="text-lg text-left text-gray-600">
            thebestsurf.com is a platform driven by AI that helps you to choose
            the most tailored surfboard for your needs based on your surfing
            style, level, preferences, and physical characteristics.
          </p>
        </div>
        
        <!-- Second Text Description Section -->
        <div class="flex-1">
          <p class="text-lg text-left text-gray-600">
            We collect as much as possible data from surfers all around the world
            to train our algorithm and provide you the best surfboard
            recommendation.
          </p>
        </div>
      </section>
    </div>

<div class=" mx-8 mb-8">
    <h2 class="text-4xl  font-bold mb-8">Why filling the form</h2>
      <!-- Text Description Sections with conditional layout -->
      <section class="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 py-6 px-8">
        <!-- First Text Description Section -->
        <div class="flex-1">
          <p class="text-lg text-left text-gray-600">
            Currently the project is in early stage, we need to get some data to train the algorithm.
          </p>
        </div>
        
        <!-- Second Text Description Section -->
        <div class="flex-1">
          <p class="text-lg text-left text-gray-600">
            By filling the form you will get a chance to win a custom surfboard of your choice and a lot of others prizes as leashes, fins, wax, etc.
          </p>
        </div>

        <div class="flex-1">
          <p class="text-lg text-left text-gray-600">
            But that's not all, you will also get all the exclusive discounts on surfboards and accessories from our partners. 🤙
          </p>
        </div>
      </section>
    </div>
  </div>
</template>


<script>

export default {
  name: "HomePagePresentation",
  // You can include data, computed properties, methods, etc., as needed.
  mounted() {
    this.animateTitle();
  },
  methods: {
    async animateTitle() {
      const title = document.getElementById("title");
      const text = "The new way to choose your next surfboard 🏄";
      let finalText = "";
      
      for (let i = 0; i < text.length; i++) {
        finalText += text[i];
        title.textContent = finalText;
        await this.delay(40); // Adjust the delay time as needed
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style scoped>
/* Scoped styles for your component */
</style>