export const questions = [
    {
      id: 1,
      type: "multiple-choice",
      text: "What is your gender?",
      options: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
      ],
      required: true,
    },
    {
      id: 2,
      type: "number",
      text: "What is your age?",
      placeholder: "eg: 20",
      validation: {
        min: 4,
        max: 99,
      },
      required: true,
    },
    {
      id: 3,
      type: "number",
      text: "What is your height? (meters)",
      placeholder: "eg: 1.82",
      validation: {
        min: 0.5,
        max: 2.5,
      },
      required: true,
    },
    {
      id: 4,
      type: "number",
      text: "What is your weight? (kg)",
      placeholder: "eg: 72",
      validation: {
        min: 20,
        max: 200,
      },
      required: true,
    },
    {
      id: 5,
      type: "multiple-choice",
      text: "What is your surfing level?",
      // options are from 1 to 10 stating at abosolute beginner to expert structured with value and label
      options: [
        { value: "1", label: "Absolute Beginner Surfers" },
        { value: "2", label: "Beginner Surfers" },
        { value: "3", label: "Beginner to Intermediate Surfers" },
        { value: "4", label: "Intermediate Surfers" },
        { value: "5", label: "Intermediate to Upper Intermediate Surfers" },
        { value: "6", label: "Upper Intermediate Surfers" },
        { value: "7", label: "Upper Intermediate to Advanced Surfers" },
        { value: "8", label: "Advanced Surfers" },
        { value: "9", label: "Advanced to Expert Surfers" },
        { value: "10", label: "Expert Surfers" },
      ],
      required: true,
    },
    {
      id: 6, // The ID should be unique among all questions
      type: "multiple-choice",
      text: "What is the average wave height you surf at?",
      options: [
        {
          value: "1-2",
          label: "1-2 feet (0.3-0.6 meters) Absolute Beginner Surfers",
        },
        {
          value: "2-3",
          label: "2-3 feet (0.6-0.9 meters) Early Intermediate Surfers",
        },
        { value: "3-4", label: "3-4 feet (0.9-1.2 meters) Intermediate Surfers" },
        {
          value: "4-5",
          label: "4-5 feet (1.2-1.5 meters) Upper Intermediate Surfers",
        },
        { value: "5-6", label: "5-6 feet (1.5-1.8 meters) Advanced Surfers" },
        { value: "6-8", label: "6-8 feet (1.8-2.4 meters) Expert Surfers" },
        {
          value: "8-12",
          label: "8-12 feet (2.4-3.7 meters) Big Wave Surfers Class I",
        },
        {
          value: "12-20",
          label: "12-20 feet (3.7-6 meters) Big Wave Surfers Class II",
        },
      ],
      required: true,
    },
    {
      id: 7, // Unique ID for the "Surfing Style" question
      type: "multiple-choice",
      text: "What is your surfing style?",
      options: [
        { value: "aggressive", label: "Aggressive/Competitive" },
        { value: "casual", label: "Casual/Recreational" },
        { value: "longboard", label: "Longboard Cruising" },
        { value: "trick", label: "Trick/Freestyle" },
      ],
      required: true,
    },
    {
      id: 8,
      type: "multiple-choice",
      text: "What is your regular surfing frequency?",
      options: [
        { value: "daily", label: "Daily" },
        { value: "weekly", label: "Weekly" },
        { value: "monthly", label: "Monthly" },
        { value: "occasionally", label: "Occasionally" },
      ],
      required: true,
    },
    {
      id: 9,
      type: "multiple-choice",
      text: "What is the most surfed wave type?",
      options: [
        { value: "beach-breaks", label: "Beach Breaks" },
        { value: "reef-breaks", label: "Reef Breaks" },
        { value: "point-breaks", label: "Point Breaks" },
        { value: "shore-breaks", label: "Shore Breaks" },
        { value: "slab-waves", label: "Slab Waves" },
        { value: "big-wave", label: "Big Wave" },
      ],
      required: true,
      otherOption: false,
    },
    {
      id: 10, // Assign a unique ID
      type: "multiple-choice",
      text: "What type of surfboard do you use?",
      options: [
        { value: "shortboard", label: "Shortboard" },
        { value: "fish", label: "Fish" },
        { value: "funboard", label: "Funboard" },
        { value: "longboard", label: "Longboard" },
        { value: "gun", label: "Gun" },
        { value: "hybrid", label: "Hybrid" },
        { value: "foamboard-soft-top", label: "Foamboard/Soft-top" },
        { value: "fish-hybrid", label: "Fish Hybrid" },
        { value: "mini-malibu", label: "Mini Malibu (Mini-Mal)" },
      ],
      required: true,
    },
    {
      id: 11, // Assign a unique ID
      type: "multiple-choice",
      text: "What is the nose shape of your surfboard?",
      options: [
        { value: "pointed-nose", label: "Pointed Nose" },
        { value: "rounded-point-nose", label: "Rounded Point Nose" },
        { value: "round-nose", label: "Round Nose" },
        { value: "squash-nose", label: "Squash Nose" },
        { value: "swallow-nose", label: "Swallow Nose" },
      ],
      required: true,
      otherOption: false, // Not a required question
    },
    {
      id: 12, // Assign a unique ID
      type: "multiple-choice-with-other",
      text: "What is the shape of your surfboard tail?",
      options: [
        { value: "square-tail", label: "Square Tail" },
        { value: "squash-tail", label: "Squash Tail" },
        { value: "round-tail", label: "Round Tail" },
        { value: "pin-tail", label: "Pin Tail" },
        { value: "swallow-tail", label: "Swallow Tail" },
        { value: "diamond-tail", label: "Diamond Tail" },
        { value: "fish-tail", label: "Fish Tail" },
        { value: "bat-tail", label: "Bat Tail" },
        { value: "other", label: "Other" },
      ],
      required: true,
      otherOption: false, // This flag indicates that there is an "Other" option that allows for custom input
    },
    {
      id: 13,
      type: "range-slider",
      text: "What is the length of your surfboard?",
      subQuestions: [
        {
          id: "length-feet",
          label: "Feet",
          min: 5, // Assuming the minimum length of a surfboard in feet
          max: 12, // Assuming the maximum length of a surfboard in feet
          step: 1, // Steps of 1 foot
        },
        {
          id: "length-inches",
          label: "Inches",
          min: 0, // Minimum inches
          max: 11, // Maximum inches (one less than the next full foot)
          step: 1, // Steps of 1 inch
        },
      ],
      required: true,
    },
    {
      id: 14,
      type: "range-slider",
      text: "What is the width of your surfboard?",
      subQuestions: [
        {
          id: "width-inches",
          label: "Inches",
          min: 15, // Assuming the minimum width in inches
          max: 25, // Assuming the maximum width in inches
          step: 0.25, // Steps in fractional inches
        },
      ],
      required: true,
    },
    {
      id: 15,
      type: "range-slider",
      text: "What is the thickness of your surfboard (Tick)?",
      subQuestions: [
        {
          id: "thickness-inches",
          label: "Inches",
          min: 1, // Assuming the minimum thickness in inches
          max: 5, // Assuming the maximum thickness in inches
          step: 0.125, // Steps in fractional inches (1/8 of an inch)
        },
      ],
      required: true,
      otherOption: false,
    },
    {
      id: 16,
      type: "number",
      text: "What is the volume of your surfboard in liters?",
      placeholder: "eg: 33.8", // This could be used as a placeholder in the input field
      validation: {
          min: 15,
          max: 120,
        },
      required: true,
    },
    {
      id: 17,
      type: "multiple-choice-with-other",
      text: "What material is your surfboard made of?",
      options: [
        { value: "eps", label: "EPS (Expanded Polystyrene)" },
        { value: "pu", label: "PU (Polyurethane)" },
        { value: "wood", label: "Wood" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    {
      id: 18,
      type: "multiple-choice-with-other",
      text: "What is the rail type of your surfboard?",
      options: [
        { value: "full-rails", label: "Full Rails" },
        { value: "medium-rails", label: "Medium Rails" },
        { value: "hard-rails", label: "Hard Rails" },
        { value: "soft-rails", label: "Soft Rails" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    {
      id: 19,
      type: "multiple-choice-with-other",
      text: "What is the rocker type of your surfboard?",
      options: [
        { value: "low-rocker", label: "Low Rocker" },
        { value: "medium-rocker", label: "Medium Rocker" },
        { value: "high-rocker", label: "High Rocker" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    {
      id: 20,
      type: "multiple-choice-with-other",
      text: "What is the bottom contour of your surfboard?",
      options: [
        { value: "flat-bottom", label: "Flat Bottom" },
        { value: "single-concave", label: "Single Concave" },
        { value: "double-concave", label: "Double Concave" },
        { value: "v-bottom", label: "V Bottom" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    {
      id: 21,
      type: "multiple-choice-with-other",
      text: "What is the fin setup of your surfboard?",
      options: [
        { value: "single-fin", label: "Single Fin" },
        { value: "twin-fin", label: "Twin Fin" },
        { value: "thruster", label: "Thruster" },
        { value: "quad", label: "Quad" },
        { value: "5-fin", label: "5 Fin" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    {
      id: 22,
      type: "scrolling-list",
      text: "Which brand is your surfboard?",
      options: [
        "Channel Islands Surfboards",
        "Firewire Surfboards",
        "Lost Surfboards",
        "JS Industries (JS Surfboards)",
        "Rusty Surfboards",
        "Pyzel Surfboards",
        "Haydenshapes",
        "DHD Surfboards",
        "SuperBrand",
        "Sharp Eye Surfboards",
        "Maurice Cole Surfboards",
        "T.Patterson Surfboards",
        "Webber Surfboards",
        "Simon Anderson Surfboards",
        "LSD Surfboards",
        "Fourth Surfboards",
        "Erik Arakawa Surfboards",
        "Pukas Surfboards",
        "Surftech",
        "Rip Curl",
        "Billabong",
        "Quiksilver",
        "O'Neill",
        "Hurley",
        "Dakine",
        "Other",
      ],
      required: true,
    },
    {
      id: 23,
      type: "multiple-choice-with-other",
      text: "What brand are your surfboard fins?",
      options: [
        { value: "fcs-i", label: "FCS I" },
        { value: "fcs-ii", label: "FCS II" },
        { value: "futures", label: "Futures" },
        { value: "quiksilver", label: "Quiksilver" },
        { value: "o'neill", label: "O'Neill" }, // Using double quotes to handle the single quote in the label
        { value: "captain-fin-co", label: "Captain Fin.Co" },
        { value: "shapers", label: "Shapers" },
        { value: "creatures-of-leisure", label: "Creatures of Leisure" },
        { value: "ocean-and-earth", label: "Ocean & Earth" },
        { value: "pro-lite", label: "Pro-Lite" },
        { value: "other", label: "Other..." },
      ],
      required: true,
      otherOption: true,
    },
    // ... more questions
  ];
