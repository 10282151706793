<template>
  <main class="w-full min-h-screen mx-auto bg-gray-50">
    <section v-if="!emailSubmitted" class="text-center py-8">
      <h1 class="text-4xl text-white px-2 font-extrabold mb-4">
        <span
          class="box-decoration-clone bg-gradient-to-r from-blue-600 to-teal-500 p-2"
        >
          Register and win <br />
          a custom surfboard
        </span>
      </h1>
      <EmailInput @submit-email="handleEmailSubmission" />
      <HomePresentation2 />
      <!-- Add HomePresentation2 component -->
      <HomePresentationImage />
      <HomePresentation />
      <!-- Add HomePresentation component -->
    </section>
    <section v-else>
      <SurveyQuestionnaire :email="submittedEmail" v-if="emailSubmitted" />
    </section>
  </main>
</template>

<script>
import EmailInput from "@/components/EmailInput.vue";
import SurveyQuestionnaire from "@/components/SurveyQuestionnaire.vue"; // Adjust the name if you've renamed Questionnaire.vue
import HomePresentation from "@/components/HomePresentation.vue"; // Import HomePresentation component
import HomePresentation2 from "@/components/HomePresentation2.vue"; // Import HomePresentation component
import HomePresentationImage from "@/components/HomePresentationImage.vue"; // Import HomePresentationImage component

export default {
  name: "HomePage",
  components: {
    EmailInput,
    SurveyQuestionnaire,
    HomePresentation2, // Register HomePresentation2 component
    HomePresentation, // Register HomePresentation component
    HomePresentationImage, // Register HomePresentationImage component
  },
  data() {
    return {
      emailSubmitted: false,
      submittedEmail: "",
    };
  },
  methods: {
    handleEmailSubmission(email) {
      //console.log('Email submitted:', email);
      this.submittedEmail = email;
      this.emailSubmitted = true;
    },
  },
};
</script>

<style scoped>
/* Your existing styles here */
</style>
