<template>
<div class=" bg-gray-50 min-h-screen">
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">Contact Us</h1>
    <form @submit.prevent="sendEmail">
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
        <input type="email" id="email" v-model="form.email" class="mt-1 block w-full rounded-md border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500" required>
      </div>
      <div class="mb-4">
        <label for="subject" class="block text-sm font-medium text-gray-700">Subject</label>
        <input type="text" id="subject" v-model="form.subject" class="mt-1 block w-full rounded-md border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500" required>
      </div>
      <div class="mb-4">
        <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
        <textarea id="message" v-model="form.message" rows="4" class="mt-1 block w-full rounded-md border-gray-300 shadow-md focus:border-indigo-500 focus:ring-indigo-500" required></textarea>
      </div>
      <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Send</button>
    </form>
  </div>
</div>
</template>

<script>
import axios from 'axios'; // Ensure axios is imported

export default {
  name: 'ContactPage',
  data() {
    return {
      form: {
        email: '',
        subject: '',
        message: '',
      },
    };
  },
  methods: {
    sendEmail() {
      if (this.form.email && this.form.subject && this.form.message) {
        const payload = {
          email: this.form.email,
          subject: this.form.subject,
          message: this.form.message,
        };

        axios.post('http://localhost:8080/api/send-email', payload)
          .then(() => {
            alert("Email sent successfully!");
            // Here you can clear the form or handle a successful submission
          })
          .catch(error => {
            console.error("Failed to send email", error);
            alert("Failed to send email. Please try again later.");
          });
      } else {
        alert("All fields are required.");
      }
    },
  },
}
</script>
