// src/services/axiosConfig.js
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance;

// Replace with your backend's base URL https://thebestsurf.com or http://localhost:8080