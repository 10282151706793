<template>
<div class="bg-gray-50 pt-6 pb-12 px-8 md:px-8">
  <h2 class="text-3xl text-blue-600 font-extrabold mb-6">What we provide you:</h2>
  <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
    <!-- Feature 1 -->
    <div class="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <img src="@/assets/dollar-sign_blue.svg" alt="Icon 1" class="mb-3" />
      <h3 class="text-lg font-semibold">Best brands, great deals</h3>
      <p class="text-sm text-gray-600 text-center mt-2">Search the best boards, compare prices</p>
    </div>
    <!-- Feature 2 -->
    <div class="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <img src="@/assets/filter_blue.svg" alt="Icon 2" class="mb-3" />
      <h3 class="text-lg font-semibold">Filter for what you want</h3>
      <p class="text-sm text-gray-600 text-center mt-2">Shortboard? Quad fin setup? Instantly customise your results.</p>
    </div>
    <!-- Feature 3 -->
    <div class="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <img src="@/assets/shopping-bag_blue.svg" alt="Icon 3" class="mb-3" />
      <h3 class="text-lg font-semibold">Buy online or Find a shop </h3>
      <p class="text-sm text-gray-600 text-center mt-2">Prefer a surfshop? find the best options around you</p>
    </div>
    <!-- Feature 4 -->
    <div class="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
      <img src="@/assets/compass_blue.svg" alt="Icon 4" class="mb-3" />
      <h3 class="text-lg font-semibold">Discover surf destinations </h3>
      <p class="text-sm text-gray-600 text-center mt-2">Holidays in mind? Find the best places to surf for you</p>
    </div>
  </div>
</div>

</template>


<script>

</script>

<style scoped>
/* Scoped styles for your component */
</style>