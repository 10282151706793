<template>
  <div class="relative max-w-screen-xl mx-auto px-8 md:px-8">
    <!-- Image Container -->
<img 
  class="w-full object-cover rounded-xl " 
  :src="require('@/assets/surf_pic_1.jpg')" 
  alt="Surfing" 
  style="height: 60vh;"
/>

    <!-- Text Container - Desktop View -->
    <div class="hidden md:block absolute bottom-0 right-0 bg-white p-4 my-6 mr-12 rounded-lg shadow-lg w-1/2">
      <h2 class="text-2xl font-bold mb-4">Check the current offers</h2>
      <p class="mb-4 text-gray-600">Catch the wave of our latest surfboard deals! 🏄‍♂️ Dive into our collection of top-notch boards.</p>
      <button class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
        Ride the Wave
      </button>
    </div>

    <!-- Text Container - Mobile View -->
    <div class="md:hidden p-4 bg-white rounded-lg shadow-lg mt-(-60vh) mb-8 ">
      <h2 class="text-2xl font-bold mb-4">Check the current offers</h2>
      <p class="mb-4 text-gray-600">Catch the wave of our latest surfboard deals! 🏄‍♂️ Dive into our collection of top-notch boards.</p>
      <button class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300">
        Ride the Wave
      </button>
    </div>
  </div>
</template>

<script>

</script>
