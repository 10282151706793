<template>
  <div class="container mx-auto p-4 bg-gray-50">
    <div
      class="flex flex-col md:flex-row mb-4 items-center justify-start space-y-4 md:space-y-0 md:space-x-4"
    >
      <!-- Multi-select Dropdown for Brands -->
      <div class="relative w-full md:w-36">
        <button
          class="w-full bg-white border border-gray-300 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex justify-between items-center px-4 py-2"
          @click="toggleDropdown($event, 'brand')"
        >
          Brands
          <svg
            class="w-4 h-4 ml-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M5.95 7.05a.5.5 0 01.7 0L10 10.293l3.35-3.243a.5.5 0 11.7.7l-3.5 3.5a.5.5 0 01-.7 0l-3.5-3.5a.5.5 0 010-.7z"
            />
          </svg>
        </button>
        <div
          v-if="dropdowns.brand"
          ref="brandDropdown"
          class="dropdown absolute bg-white border border-gray-300 w-full z-20 rounded-md shadow-md mt-1"
        >
          <label
            v-for="brand in brands"
            :key="brand"
            class="flex items-center p-2 hover:bg-gray-100"
          >
            <input
              type="checkbox"
              :value="brand"
              v-model="selectedBrands"
              class="form-checkbox"
            />
            <span class="ml-2 text-sm text-gray-700">{{ brand }}</span>
          </label>
        </div>
      </div>

      <!-- Multi-select Dropdown for Ride Types -->
      <div class="relative w-full md:w-36">
        <button
          class="w-full bg-white border border-gray-300 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex justify-between items-center px-4 py-2"
          @click="toggleDropdown($event, 'rideType')"
        >
          Ride Types
          <svg
            class="w-4 h-4 ml-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M5.95 7.05a.5.5 0 01.7 0L10 10.293l3.35-3.243a.5.5 0 11.7.7l-3.5 3.5a.5.5 0 01-.7 0l-3.5-3.5a.5.5 0 010-.7z"
            />
          </svg>
        </button>
        <div
          v-if="dropdowns.rideType"
          ref="rideTypeDropdown"
          class="dropdown absolute bg-white border border-gray-300 w-full z-20 rounded-md shadow-md mt-1"
        >
          <label
            v-for="type in rideTypes"
            :key="type.ride_type_id"
            class="flex items-center p-2 hover:bg-gray-100"
          >
            <input
              type="checkbox"
              :value="type.ride_type_id"
              v-model="selectedRideTypes"
              class="form-checkbox"
            />
            <span class="ml-2 text-sm text-gray-700">{{
              type.description
            }}</span>
          </label>
        </div>
      </div>

      <!-- Multi-select Dropdown for Surfaces -->
      <div v-if="isKiteProductType" class="relative w-full md:w-36">
        <button
          class="w-full bg-white border border-gray-300 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex justify-between items-center px-4 py-2"
          @click="toggleDropdown($event, 'surface')"
        >
          Surfaces
          <svg
            class="w-4 h-4 ml-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M5.95 7.05a.5.5 0 01.7 0L10 10.293l3.35-3.243a.5.5 0 11.7.7l-3.5 3.5a.5.5 0 01-.7 0l-3.5-3.5a.5.5 0 010-.7z"
            />
          </svg>
        </button>
        <div
          v-if="dropdowns.surface"
          ref="surfaceDropdown"
          class="dropdown absolute bg-white border border-gray-300 w-full z-20 rounded-md shadow-md mt-1"
        >
          <label
            v-for="surface in surfaces"
            :key="surface.value"
            class="flex items-center p-2 hover:bg-gray-100"
          >
            <input
              type="checkbox"
              :value="surface.value"
              v-model="selectedSurfaces"
              class="form-checkbox"
            />
            <span class="ml-2 text-sm text-gray-700">{{ surface.label }}</span>
          </label>
        </div>
      </div>

      <!-- Multi-select Dropdown for Lengths (Twintip) -->
      <div v-if="isTwintipProductType" class="relative w-full md:w-36">
        <button
          class="w-full bg-white border border-gray-300 text-gray-700 text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent flex justify-between items-center px-4 py-2"
          @click="toggleDropdown($event, 'length')"
        >
          Lengths
          <svg
            class="w-4 h-4 ml-2 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M5.95 7.05a.5.5 0 01.7 0L10 10.293l3.35-3.243a.5.5 0 11.7.7l-3.5 3.5a.5.5 0 01-.7 0l-3.5-3.5a.5.5 0 010-.7z"
            />
          </svg>
        </button>
        <div
          v-if="dropdowns.length"
          ref="lengthDropdown"
          class="dropdown absolute bg-white border border-gray-300 w-full z-20 rounded-md shadow-md mt-1 overflow-auto max-h-60"
        >
          <label
            v-for="length in lengthsTwintip"
            :key="length.value"
            class="flex items-center p-2 hover:bg-gray-100"
          >
            <input
              type="checkbox"
              :value="length.value"
              v-model="selectedLengths"
              class="form-checkbox"
            />
            <span class="ml-2 text-sm text-gray-700">{{ length.label }}</span>
          </label>
        </div>
      </div>

      <!-- Reset Filter Button -->
      <button
        @click="resetFilter"
        class="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm hover:shadow-md transition duration-200 ease-in-out"
      >
        Reset Filter
      </button>
    </div>

    <!-- Products Grid -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div
        v-for="product in filteredProducts"
        :key="product.product_id"
        class="border p-4 rounded bg-white shadow-sm hover:shadow-lg transition duration-300 ease-in-out"
        @click="
          $router.push({
            name: 'ProductDetails',
            params: { productId: product.product_id },
          })
        "
      >
        <img
          :src="product.image_url"
          alt=""
          class="object-contain h-48 w-full"
        />
        <h3 class="mt-2 font-bold">
          {{ product.brand }} {{ product.model_name }}
        </h3>
        <p class="text-gray-600">{{ product.product_type }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/services/axiosConfig.js";

export default {
  data() {
    return {
      products: [],
      rideTypes: [],
      brands: [],
      surfaces: [],
      lengthsTwintip: [],
      selectedBrands: [],
      selectedRideTypes: [],
      selectedSurfaces: [],
      selectedLengths: [],
      dropdowns: {
        brand: false,
        rideType: false,
        surface: false,
        length: false,
      },
      // Add a reference to the click listener so it can be added or removed
      outsideClickListener: null,
    };
  },
computed: {
  filteredProducts() {
    return this.products.filter((product) => {
      const matchesBrand = !this.selectedBrands.length || this.selectedBrands.includes(product.brand);
      const matchesRideType = !this.selectedRideTypes.length || this.selectedRideTypes.some(rideTypeId => product.ride_type_ids.includes(rideTypeId));

      let matchesSurfaceOrLength = true; // Assume true by default

      if (this.isKiteProductType) {
        matchesSurfaceOrLength = !this.selectedSurfaces.length || this.selectedSurfaces.some(surfaceValue => this.surfaceProductIds[surfaceValue]?.includes(product.product_id));
      } else if (this.isTwintipProductType) {
        matchesSurfaceOrLength = !this.selectedLengths.length || this.selectedLengths.some(lengthValue => this.lengthProductIds[lengthValue]?.includes(product.product_id));
      }

      return matchesBrand && matchesRideType && matchesSurfaceOrLength;
    });
  },
    isKiteProductType() {
      return this.$route.params.productType === "Kite";
    },
    isTwintipProductType() {
      return this.$route.params.productType === "Twintip";
    },
  },
watch: {
  "$route.params.productType": {
    immediate: true,
    handler(newVal) {
      this.fetchProducts(); // Refetch products whenever the route parameter changes
      if (newVal === "Kite") {
        this.fetchSurfaces();
        this.selectedLengths = []; // Clear selected lengths when switching to Kite
      } else if (newVal === "Twintip") {
        this.fetchLengthsTwintip();
        this.selectedSurfaces = []; // Clear selected surfaces when switching to Twintip
      }
    },
  },
},
  methods: {
    fetchProducts() {
      const productType = this.$route.params.productType || "All"; // Default to 'Kite' if no parameter is provided
      axios
        .get("/api/products", {
          params: {
            product_type: productType,
          },
        })
        .then((response) => {
          this.products = response.data;
          this.brands = [
            ...new Set(response.data.map((product) => product.brand)),
          ];
        });
    },
    fetchRideTypes() {
      axios
        .get("/api/ride-types")
        .then((response) => {
          this.rideTypes = response.data;
        })
        .catch((error) => {
          console.error("There was an error fetching the ride types:", error);
        });
    },
    fetchSurfaces() {
      axios
        .get("/api/surfaces")
        .then((response) => {
          this.surfaces = response.data;
          this.surfaceProductIds = response.data.reduce((acc, surface) => {
            acc[surface.value] = surface.productIds;
            return acc;
          }, {});
        })
        .catch((error) => {
          console.error("There was an error fetching the surfaces:", error);
        });
    },
    fetchLengthsTwintip() {
      axios
        .get("/api/lengths_twintip")
        .then((response) => {
          this.lengthsTwintip = response.data;
          // Here, we can assume each length includes the 'value' and an array of 'productIds' just like surfaces
          this.lengthProductIds = response.data.reduce((acc, length) => {
            acc[length.value] = length.productIds;
            return acc;
          }, {});
        })
        .catch((error) => {
          console.error(
            "There was an error fetching the lengths for Twintip:",
            error
          );
        });
    },
    resetFilter() {
      this.selectedBrands = [];
      this.selectedRideTypes = [];
      this.selectedSurfaces = [];
      this.selectedLengths = [];
      this.closeAllDropdowns();
    },
    closeAllDropdowns() {
      Object.keys(this.dropdowns).forEach((key) => {
        this.dropdowns[key] = false;
      });
      // Remove the click listener for outside clicks if it exists
      if (this.outsideClickListener) {
        document.removeEventListener("click", this.outsideClickListener);
        this.outsideClickListener = null;
      }
    },
    toggleDropdown(event, dropdown) {
      event.stopPropagation();
      // Toggle the current dropdown state
      console.log("Dropdown toggled:", dropdown);
      this.dropdowns[dropdown] = !this.dropdowns[dropdown];

      // Immediately stop listening if no dropdown is open
      const anyDropdownOpen = Object.values(this.dropdowns).some(
        (status) => status
      );
      if (!anyDropdownOpen && this.outsideClickListener) {
        document.removeEventListener("click", this.outsideClickListener);
        this.outsideClickListener = null;
      } else if (!this.outsideClickListener) {
        // Add the listener if not already added
        this.outsideClickListener = (event) => this.handleOutsideClick(event);
        document.addEventListener("click", this.outsideClickListener);
      }
    },
    handleOutsideClick(event) {
      let isOutside = true;
      // Check each dropdown
      if (
        this.$refs.brandDropdown &&
        this.$refs.brandDropdown.contains(event.target)
      ) {
        isOutside = false;
      }
      if (
        this.$refs.rideTypeDropdown &&
        this.$refs.rideTypeDropdown.contains(event.target)
      ) {
        isOutside = false;
      }
      if (
        this.$refs.surfaceDropdown &&
        this.$refs.surfaceDropdown.contains(event.target)
      ) {
        isOutside = false;
      }
      if (
        this.$refs.lengthDropdown &&
        this.$refs.lengthDropdown.contains(event.target)
      ) {
        isOutside = false;
      }

      // If the click was outside all open dropdowns, close them
      if (isOutside) {
        this.closeAllDropdowns();
      }
    },
  },
  mounted() {
    this.fetchProducts();
    this.fetchRideTypes();
    // Only fetch surfaces and lengths if necessary
    if (this.isKiteProductType) {
      this.fetchSurfaces();
    } else if (this.isTwintipProductType) {
      this.fetchLengthsTwintip();
    }
  },
  beforeUnmount() {
    if (this.outsideClickListener) {
      document.removeEventListener("click", this.outsideClickListener);
    }
  },
};
</script>
